.about-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.about-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-title {
  color: white;
  font-size: 3em;
  width: 50vw;
  border-bottom: 1px solid #fff;
  margin: 2vh 0 2vh;
  line-height: 0.1em !important;

  a {
    background: #212223;
    text-decoration: none;
    color: white;
    padding-right: 0.5em;
    margin-top: 10em;
  }

  #about {
    padding-top: 8em;
    cursor: default;
  }
}

.info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5em;

  .about-info {
    font-size: 2em;
    max-width: 40%;
    margin-right: 5em;
  }

  img {
    height: auto;
    width: 35em;
  }
}

.about-icon {
  font-size: 3em;
  margin: 0.5em;
}

@media only screen and (max-width: 1000px) {
  .info-wrapper {
    .about-info {
      font-size: 1.5em;
    }

    img {
      width: 20em;
    }
  }
}

@media only screen and (max-width: 800px) {
  .info-wrapper {
    flex-direction: column-reverse;
    .about-info {
      font-size: 2em;
      margin: 2em 0;
      min-width: 80vw;
    }

    img {
      width: 15em;
    }
  }

  #projects {
    font-size: 0.75em;
  }
}
