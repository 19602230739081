.nav-bar {
  background: #181818;
  height: 8vh;
  min-height: 8em;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;

  .btn {
    margin: 1em 2em;
    cursor: pointer;
    font-size: large;
  }

  .resume-btn {
    margin: 1em 2em;
    padding: 0.75em;
    font-size: 1.5em;
    color: #4a98bf;
    background-color: transparent;
    border: 1px solid #4a98bf;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;

    a {
      text-decoration: none;
      color: #4a98bf;
    }

    &:hover {
      background-color: #4a98bf25;
    }
  }

  .about-wrap,
  .projects-wrap,
  .contact-wrap {
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35%;
    width: 8em;
    cursor: pointer;

    a {
      opacity: 0;
      position: absolute;
      text-decoration: none;
      color: #9c9c9c;
      font-size: 1.5em;
    }

    &:hover {
      a {
        animation: fadein 0.2s;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
      }

      .about-icon,
      .projects-icon,
      .contact-icon {
        animation: fadeout 0.2s;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    color: white;
  }
}
