.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.text-animate-hover {
  min-width: 10px;
  display: inline-block;
  // animation-fill-mode: both;

  &:hover {
    animation: rubberBand 1s;
    color: #4a98bf;
    filter: drop-shadow(0 0 0.25rem #4a98bf50);
    cursor: pointer;
  }
}

@for $i from 10 through 29 {
  .text-animate._#{$i} {
    animation-delay: #{$i / 10}s;
    animation-fill-mode: forwards;
  }
}
