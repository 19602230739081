.github-icon {
  font-size: 3em;
  margin: 0.5em;

  &:hover {
    background-color: transparent;
  }
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 600px) {
}
